@import url(https://unpkg.com/bootstrap@5.0.1/dist/css/bootstrap.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer{
  position:fixed;
  bottom: 0;
  width: 100%;
  height:40px;
  background-color: black;
  z-index: 999;
  overflow:hidden;
}

.wideTextArea{
  width:700px;
}

.examForm{
  max-width: 960px;
  margin:auto auto 70px auto;
}

.examForm .p-field {
  margin-bottom: 1.5rem;
}

.label{
  margin-bottom: 2px;
  margin-right: 5px;
  width: 170px;
  text-align: left;
}

.input{
  width:300px
}

.logo{
  margin:auto 100px;
  width:100px;
}

.App {
  text-align: center;
 
  overflow:auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.background{
  position:fixed;
  top: 200px;
  right:10px;
  height:750px;
  width:700px;
  background-image: url(/static/media/spa.8342470e.png);
	background-repeat: no-repeat;
  background-position:bottom left;
  z-index: -1;
  opacity: 0.3;
}

body .p-tabview-selected a.p-tabview-nav-link  {
  color:rgb(139, 160, 160) !important;
  background-color: #363431 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.homeComponent{
  width:1700px;
  border: 1px solid #ccc!important;
  border-radius: 3px;
}

.divinum{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.divinumDataTable.p-datatable .p-datatable-tbody > tr > td{
  overflow: hidden;
}

.divinumDataTable.p-datatable .p-column-filter{
  width:150px;
}

